.helper {
    background: rgb(182, 212, 254);
    background: linear-gradient(0deg, rgba(182, 212, 254, 1) 0%, rgba(255, 255, 255, 1) 100%);
    border-radius: 5px;
}

.info {
    margin: 1px;
    padding: 0 1rem 1rem 1rem;
    background: white;
    border-radius: 5px;
}
