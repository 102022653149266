.acceptance {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.checkbox {
    width: 2em;
    height: 2em;
    min-width: 2em;
    min-height: 2em;
    max-width: 2em;
    max-height: 2em;
    cursor: pointer;
}

.link {
    color: #ab0920;
    text-decoration: none;
    cursor: pointer;
}

.link:hover, .link:focus {
    color: #93081b;
}
