.page {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
}

.header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.travelers {
    display: grid;
    gap: 0.5rem;
    align-items: center;
    grid-template-columns: auto auto;
}

.timer {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
