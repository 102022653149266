.calendar {
    width: calc(100% + 2rem);
    margin: 1rem -1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
}

.date {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    width: 52px;
    height: 52px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dow {
    font-size: .85rem;
    opacity: .85;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: .5rem;
}

.available {
    color: #28a745;
    cursor: pointer;
}

.available:hover {
    background: #86e29b
}

.limited {
    color: #ffc107;
    cursor: pointer;
}

.limited:hover {
    background: #ffe7a0
}

.closed {
    pointer-events: none;
    color: #e9ecef;
}

.current {
    cursor: auto;
    color: #fff;
    pointer-events: none;
}

.available.current {
    background: #28a745;
}

.limited.current {
    background: #ffc107;
}

.soldOut {
    color: #f3b7bd;
    text-decoration: line-through;
    pointer-events: none;
}

.legend {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.redLink {
    color: #ab0920;
}

.bottomNavbar {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.topNavbar {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
}
