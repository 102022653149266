.availability {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-right: auto;
    cursor: pointer;
}

.info {
    display: flex;
    flex-direction: column;
}

.time {
    font-weight: 600;
    line-height: 1.5;
    font-size: 1rem;
}

.status {
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
}

.disabled {
    pointer-events: none;
    position: relative;
    opacity: .5;
}

.disabled::after {
    top: 50%;
    background: #354052;
    opacity: .7;
    content: '';
    width: 110%;
    position: absolute;
    height: .1em;
    border-radius: .1em;
    left: -5%;
    white-space: nowrap;
    display: block;
    transform: rotate(-15deg);
}
