.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
}

.message {
    height: 194px;
    margin: auto;
    line-height: 194px;
}
