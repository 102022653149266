.legend {
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.circle {
    width: .875rem;
    height: .875rem;
    border: 2px solid;
    border-radius: 50%;
}

.red {
    color: #dc3545
}

.yellow {
    color: #ffc107
}

.green {
    color: #28a745
}
