.counter {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #FF9400;
    color: white;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1rem;
}

.select {
    color: white;
    border: 0;
    /*width: 3rem;*/
    background: #FF9400 url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 1.5rem;
    background-size: 2rem 3rem;
    width: 3rem;
    appearance: none;
    cursor: pointer;
    outline: none;
}
