.title {
    white-space: pre;
    margin-bottom: 0;
}

.asterisk {
    margin-left: 0.1rem;
}

.label {
    font-size: 14px;
}
