html {
    overflow: hidden;
}

body {
    background-color: transparent;
}

#root {
    background-color: transparent;
}

.app {
    width: 100%;
    min-height: 250px;
    background: white;
}
