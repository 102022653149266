.next {
    width: 100%;
    background: #FF9400;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    cursor: pointer;
    display: block;
    text-align: center;
    padding: 1rem;
    outline: none;
    border: none;
}

.disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
}

.next:hover, .next:focus {
    opacity: 0.8;
}

.next:active {
    opacity: 0.7;
}
