@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    src: local('Inter'), url(fonts/Inter/static/Inter-Thin.ttf);
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    src: local('Inter'), url(fonts/Inter/static/Inter-ExtraLight.ttf);
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: local('Inter'), url(fonts/Inter/static/Inter-Light.ttf);
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local('Inter'), url(fonts/Inter/static/Inter-Regular.ttf);
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: local('Inter'), url(fonts/Inter/static/Inter-Medium.ttf);
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: local('Inter'), url(fonts/Inter/static/Inter-SemiBold.ttf);
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: local('Inter'), url(fonts/Inter/static/Inter-Bold.ttf);
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: local('Inter'), url(fonts/Inter/static/Inter-ExtraBold.ttf);
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: local('Inter'), url(fonts/Inter/static/Inter-Black.ttf);
}

@font-face {
    font-family: "TwemojiCountryFlags";
    unicode-range: U+1F1E6-1F1FF, U+1F3F4, U+E0062-E0063, U+E0065, U+E0067,
    U+E006C, U+E006E, U+E0073-E0074, U+E0077, U+E007F;
    src: url(fonts/Twemoji/TwemojiCountryFlags.woff2) format('woff');
    font-display: swap;
}

body {
    --bs-body-font-family: Inter;
}
