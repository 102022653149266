.title {
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.2;
}

.subtitle {
    font-size: 0.8rem;
    line-height: 1.2;
    color: gray;
}

.component {
    display: flex;
    justify-content: space-between;
}

.right {
    display: flex;
    flex-direction: column;
}

.left {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.free {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: green;
}
