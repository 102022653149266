.page {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #f8f9fa;
    gap: 1px;
}

.input > * {
    background: #ffffff;
    padding: 1rem;
}

.loading {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.5;
    cursor: wait;
}

.error {
    padding: 0 1rem;
}
