.page {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
}

.form {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.card {
    background-color: #5469d4;
    padding: 11px 15px 11px 15px;
    border-radius: 5px;
}

.header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.sign::placeholder {
    color: lightgray;
}

.timer {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
