.form {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.card {
    background-color: #5469d4;
    padding: 11px 15px 11px 15px;
    border-radius: 5px;
}

.error {
    margin-left: 1rem;
    margin-right: 1rem;
}

.label {
    color: #084298;
    font-size: 13px;
}
